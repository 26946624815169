import * as React from "react";
import { css, useTheme } from "@emotion/react";

import Navigation from "../Navigation";
import Footer from "../Footer";
import Seo from "../Seo";

import phrases from "../constants/formularzPhrases";

const constainerStyle = (theme) => css`
  min-height: 100vh;
  background-color: ${theme.colors.red};
  color: ${theme.colors.whiteText};

  section {
    scroll-margin-top: 75px;
  }
`;

const innerContainerStyle = css`
  padding: 0 20px;
`;

const sectionContainerStyle = (theme) => css`
  ${innerContainerStyle};
  h1 {
    ${theme.emotionTypography.lead};
    margin: 0;
    padding-top: 20px;
    line-height: 1em;
  }
`;

const lead = (theme) => css`
  ${theme.emotionTypography.smallLead};
  margin-bottom: 150px;
  text-indent: 3em;
  max-width: 900px;
  margin-left: auto;
`;

const formSectionStyle = (theme) => css`
  border-top: 1px solid ${theme.colors.whiteText};
  padding-top: 20px;
  padding-bottom: 100px;
  display: grid;
  column-gap: 50px;
  ${theme.grids.thirtyToOne};
`;

const submitContainerStyle = (theme) => css`
  border-top: 1px solid ${theme.colors.whiteText};
  padding-top: 20px;
  padding-bottom: 100px;
`;

const formSectionDescpription = css`
  margin: 0;
`;

const formRowStyle = (theme) => css`
  display: flex;

  label {
    min-width: 100px;
  }
  input {
    width: 100%;
    margin-left: 20px;
    border: 0;
    border-bottom: 1px solid ${theme.colors.whiteText};
    color: inherit;
    font-size: 16px;
    background-color: transparent;
    padding: 8px;
    border-radius: 0;
  }
`;

const fileRowStyle = (theme) => css`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.whiteText};
  font-size: 1.4em;
  display: grid;
  grid-template-columns: 1fr 200px;
  align-items: center;
  padding: 10px 0;
`;

const submitContentStyle = css`
  margin-left: auto;
  max-width: 450px;
`;

const submitButtonStyle = (theme) => css`
  ${theme.emotionTypography.smallLead};
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: 1px solid currentColor;
  padding: 5px 15px;
  width: 100%;
  display: inline-block;
  margin-top: 20px;
`;

const finalSentenceContainerStyle = css`
  max-width: 650px;
  margin-left: auto;
`

const FormularzPage = ({ pageContext }) => {
  const theme = useTheme();
  const language = pageContext?.language || "pl";
  const otherLanguageUrl = language === "pl" ? "/en/form" : "/formularz";
  return (
    <>
      <Seo
        language={language}
        title={language === "pl" ? "Formularz" : "Form"}
      />
      <div css={constainerStyle}>
        <Navigation
          foreground={theme.colors.whiteText}
          background={theme.colors.red}
          language={language}
          languageUrl={otherLanguageUrl}
        >
          <ul>
            <li>
              <a href="#formularz">{phrases[0][language]}</a>
            </li>
          </ul>
        </Navigation>
        <section id="#formularz" css={sectionContainerStyle}>
          <h1>{phrases[0][language]}</h1>
          <p css={lead}>{phrases[1][language]}</p>
          <div>
            <form data-netlify="true" name="zgloszenie" method="POST">
            <input type="hidden" name="form-name" value="zgloszenie" />
              <div css={formSectionStyle}>
                <div>
                  <p css={formSectionDescpription}>{phrases[2][language]}</p>
                </div>
                <div>
                  <div css={formRowStyle}>
                    <label htmlFor="name">{phrases[3][language]}</label>
                    <input required id="name" name="name" type="text" />
                  </div>
                  <div css={formRowStyle}>
                    <label htmlFor="surname">{phrases[4][language]}</label>
                    <input required id="surname" name="surname" type="text" />
                  </div>
                  <div css={formRowStyle}>
                    <label htmlFor="email">{phrases[5][language]}</label>
                    <input required id="email" name="email" type="email" />
                  </div>
                  <div css={formRowStyle}>
                    <label htmlFor="phone">{phrases[6][language]}</label>
                    <input required id="phone" name="phone" type="phone" />
                  </div>
                </div>
              </div>
              <div css={formSectionStyle}>
                <p css={formSectionDescpription}>{phrases[7][language]}</p>
                <div>
                  <div css={fileRowStyle}>
                    <label htmlFor="szklo-jako-element">
                      {phrases[8][language]}
                    </label>
                    <input
                      required
                      id="szklo-jako-element"
                      name="szklo-jako-element"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <div css={fileRowStyle}>
                    <label htmlFor="ceramika-jako-zasob">
                      {phrases[9][language]}
                    </label>
                    <input
                      required
                      id="ceramika-jako-zasob"
                      name="ceramika-jako-zasob"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <div css={fileRowStyle}>
                    <label htmlFor="szkole-jako-element-dekoracyjny">
                      {phrases[10][language]}
                    </label>
                    <input
                      required
                      id="szkole-jako-element-dekoracyjny"
                      name="szkole-jako-element-dekoracyjny"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <div css={fileRowStyle}>
                    <label htmlFor="romantyczna-ceramika">
                      {phrases[11][language]}
                    </label>
                    <input
                      required
                      id="romantyczna-ceramika"
                      name="romantyczna-ceramika"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <div css={fileRowStyle}>
                    <label htmlFor="szklo-jako-granica">
                      {phrases[12][language]}
                    </label>
                    <input
                      required
                      id="szklo-jako-granica"
                      name="szklo-jako-granica"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <div css={fileRowStyle}>
                    <label htmlFor="rodzinne-artefakty">
                      {phrases[13][language]}
                    </label>
                    <input
                      required
                      id="rodzinne-artefakty"
                      name="rodzinne-artefakty"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
              <div css={finalSentenceContainerStyle}>
                <p>{phrases[14][language]}</p>
              </div>
              <div css={submitContainerStyle}>
                <div css={submitContentStyle}>
                  <div>
                    <input type="checkbox" id="rodo" name="rodo" required />
                    <label htmlFor="rodo"> {phrases[16][language]}</label>
                  </div>
                  <button type="submit" css={submitButtonStyle}>
                    {phrases[15][language]}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer language={language} />
    </>
  );
};

export default FormularzPage;
