const phrases = [
    //0
    {
        pl: 'Formularz',
        en: 'Form',
    },
    //1
    {
        pl: 'Jeżeli rozwiązałeś wszystkie zagadki, możesz wysłać odpowiedzi za pomocą tego formularza',
        en: 'If you have solved all the puzzles, you can send your answers using this form',
    },
    //2
    {
        pl: 'Dane kontaktowe',
        en: 'Contact details'
    },
    //3
    {
        pl: 'Imię',
        en: 'Name',
    },
    //4
    {
        pl: 'Nazwisko',
        en: 'Surname'
    },
    //5
    {
        pl: 'e-mail',
        en: 'e-mail'
    },
    //6
    {
        pl: 'telefon',
        en: 'phone number'
    },
    //7
    {
        pl: 'Dostosuj wykonane podczas gry zdjęcia zdjęcia do podanych poniżej kategorii.',
        en: 'Adjust the photos taken during the game to the categories listed below.'
    },
    //8
    {
        pl: 'Szkło jako element tożsamości miejsca',
        en: 'Glass as an element of the identity of a place'
    },
    //9
    {
        pl: 'Ceramika jako zasób naturalny',
        en: 'Ceramics as a natural resource'
    },
    //10
    {
        pl: 'Szkło jako element dekoracyjny',
        en: 'Glass as a decorative element'
    },
    //11
    {
        pl: 'Romantyczna Ceramika',
        en: 'Romantic Ceramics'
    },
    //12
    {
        pl: 'Szkło jako granica',
        en: 'Glass as a border'
    },
    //13
    {
        pl: 'Rodzinne artefakty',
        en: 'Family artifacts'
    },
    //14
    {
        pl: 'Po poprawnym dodaniu zdjęć do wszystkich kategorii Wyślij formularz, a my odeślemy Ci Zadanie Finałowe. Wykonaj je i zakończ grę. Jury w składzie ekspertów przyzna nagrody w ciągu dwóch tygodni od zakończenia projektu.',
        en: 'After adding photos to all categories, please send the form and we will send you back the Final Task. Complete it and end the game. The jury composed of experts will award the prizes within two weeks of the end of the project.',
    },
    //15
    {
        pl: 'wyślij',
        en: 'send'
    },
    //16
    {
        pl: 'Zapoznałem się z zasadami Rodo',
        en: 'I’m familiar with the rules of the Rodo',
    }
]

export default phrases
